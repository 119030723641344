export enum ActivityStatus {
  Pendingapproval = "pendingapproval",
  Pendingshipment = "pendingshipment",
  Canceled = "canceled",
  Canceledbybuyer = "canceledbybuyer",
  Returntoseller = "returntoseller",
  Canceledbywholesale = "canceledbywholesaler",
  Reversepickupapproved = "reversepickupapproved",
  Outfordelivery = "outfordelivery",
  Faileddelivery = "faileddelivery",
  Returntosellerinitiated = "returntosellerinitiated",
  Reversepickupshipment = "reversepickupshipment",
  Returndelivered = "returndelivered",

  Delivered = "delivered",
  Returneligible = "returneligible",
  Returnwindowclosed = "returnwindowclosed",
  Returnrequestraised = "returnrequestraised",
  Reversepickupdone = "reversepickupdone",
  Returnrequestdeclined = "returnrequestdeclined",
  Refundprocessed = "refundprocessed",
  Refundfailed = "refundfailed",
}

export enum DurationEventEndType {
  BACK = "back_click",
  CART = "cart_click",
  PRODUCT_TILE = "product_tile_click",
  VIDEO_CHANGE = "video_change",
}

export enum Domain {
  Shoptab = "roposo.com",
  Shipstreak = "shipstreak.com",
  Dash101 = "dash101.in",
  Zyxw365 = "zyxw365.in",
  Localhost = "localhost",
}

export enum ModuleName {
  LIST = "list",
  DETAILS = "details",
  FORM = "form",
  BANK = "bank",
  CONFIRMATION = "confirmation",
  REJECTION = "rejection",
  NOTFOUND = "notfound",
}
