import { NextPageContext } from "next";
import { RedirectRoute, WindowInterface } from "types";
import {
  getGlancePreference,
  getGlanceUserData,
  isAppInstalled,
  isGlance,
  isRoposo,
  setGlancePreference,
} from "./app-native";
import { setCookie, COOKIES, getCookieDomain, getCookie } from "./cookies";
import { v4 as uuidv4 } from "uuid";
import { shoptabContext } from "./analytics";
import { OEM, OEM_PACKAGENAME_MAP, shoptabRoutes } from "constants/constants";
import { Domain } from "../constants/enums";

export const myWindow: WindowInterface | null =
  typeof window !== "undefined" ? window : null;

export const getDiscountPercentFromPrices = (
  originalPrice: number,
  discountPrice: number
) => {
  return Math.round(100 * (1 - discountPrice / originalPrice));
};

export const slugify = (text: string) =>
  !text
    ? text
    : text
        .toLowerCase()
        .replace(/[^\w ]+/g, "-")
        .replace(/ +/g, "-");

export const getState = (router: any) => {
  const params: any = {};
  if (router.query.userid) {
    params.userid = router.query.userid;
  }
  if (router.query.roposostoreid) {
    params.roposostoreid = router.query.roposostoreid;
  }
  if (router.query.storehandle) {
    params.storehandle = router.query.storehandle;
  }
  if (router.query.externalappname) {
    params.externalappname = router.query.externalappname;
  }
  if (router.query.creatorId) {
    params.creatorId = router.query.creatorId;
  }
  if (router.query.externalappjwt) {
    params.externalappjwt = router.query.externalappjwt;
  }
  if (router.query.sessionid) {
    params.sessionid = router.query.sessionid;
  }
  if (router.query.src) {
    params.src = router.query.src;
  }
  if (router.query.isBuyNow) {
    params.isBuyNow = router.query.isBuyNow;
  }
  if (router.query.buyNowVariantId) {
    params.buyNowVariantId = router.query.buyNowVariantId;
  }
  if (router.query.buyNowVariantQuantity) {
    params.buyNowVariantQuantity = router.query.buyNowVariantQuantity;
  }
  if (router.query.themeId) {
    params.themeId = router.query.themeId;
  }
  return params;
};

// @ts-ignore
export const redirect = ({ pathname, query }: RedirectRoute) => {
  const domain = getCookieDomain(window.location.host || "");
  setCookie(null, COOKIES.src, pathnameToSrc(window.location.pathname), domain);
  if (query) {
    delete query.src;
  }
  window.location.href = `${pathname}${
    query ? `?${new URLSearchParams(query).toString()}` : ""
  }`;
};

export const redirectServerSide = async (
  ctx: NextPageContext,
  location: any
) => {
  if (ctx.req) {
    ctx.res?.writeHead(302, { Location: location });
    ctx.res?.end();
  }
  return { props: {} };
};

const pathnameToSrc = (pathname: string) => {
  if (/\/(.*)\/(.*)\/(\d+)/.test(pathname)) {
    return "product_details_page";
  }
  switch (pathname) {
    case "/payment-review":
      return "payment_page";
    default:
      return "";
  }
};

export function getGpId() {
  const qParamObj = getQueryParamObject();
  let gpId = "";
  if (qParamObj) {
    gpId = qParamObj?.gpId || qParamObj?.gpid;
  }
  if (!gpId) {
    const userData = getGlanceUserData();
    gpId = userData ? JSON.parse(userData)?.gpid : "";
  }
  if (gpId) {
    setGlancePreference("gpId", String(gpId));
  } else {
    gpId = getGlancePreference("gpId");
  }
  return gpId;
}

export function getGlanceId() {
  const qParamObj = getQueryParamObject();
  let glanceId = null;
  if (qParamObj) {
    glanceId = qParamObj?.utm_medium;
  }
  if (glanceId) {
    myWindow?.sessionStorage.setItem("glanceId", String(glanceId));
  } else {
    glanceId = myWindow?.sessionStorage.getItem("glanceId");
  }
  return glanceId;
}

export function removeUndefinedNull(obj: any) {
  const newObj: any = {};
  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export function getGuestUserId() {
  // getting userId from query param and Make keyName same for web feed and shopify both code base
  const { glance_user_id = null, uId } = getQueryParamObject();
  const userData = getGlanceUserData();
  let guestUserId = getGlancePreference("guestUserId");
  try {
    if (!guestUserId) {
      if (glance_user_id) {
        guestUserId = glance_user_id;
      } else {
        guestUserId =
          isGlance() && userData
            ? JSON.parse(userData)?.userId
            : isRoposo()
            ? getCookie(null, "user_id_usr")
            : null;
      }
      if (!guestUserId) {
        guestUserId = uId;
      }
      if (!guestUserId) {
        guestUserId = uuidv4();
      }
      setGlancePreference("guestUserId", guestUserId);
    }
    return guestUserId;
  } catch (err) {
    console.error(err);
    if (!guestUserId) {
      guestUserId = uuidv4();
      setGlancePreference("guestUserId", guestUserId);
    }
    return guestUserId;
  }
}

export function isWebView() {
  if (typeof window === "undefined") return false;
  var standalone = myWindow?.navigator?.standalone,
    userAgent = window.navigator?.userAgent?.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);
  if (ios) {
    if (!standalone && !safari) {
      //ios webview
      return true;
    }
  } else {
    if (userAgent.includes("wv")) {
      // Android webview
      return true;
    }
  }
  return false;
}

export function getCurrentPageName(router: any) {
  const pathName: string = router.pathname;
  if (pathName.includes(shoptabRoutes.collection)) {
    return shoptabContext.TRENDS_PAGE;
  } else if (pathName.includes(shoptabRoutes.playAndWin)) {
    return shoptabContext.PLAY_AND_WIN;
  } else if (pathName.includes(shoptabRoutes.videoFeed)) {
    return shoptabContext.VIDEO_FEED;
  } else if (pathName.includes(shoptabRoutes.onboarding)) {
    return shoptabContext.ONBOARDING;
  } else {
    return shoptabContext.MAIN_PAGE;
  }
}

export const getOem = (): string => {
  const packageMap: any = OEM_PACKAGENAME_MAP;
  for (const oem in packageMap) {
    const oemList = packageMap[oem];
    const matches = oemList.some((packageName: string) =>
      isAppInstalled(packageName)
    );
    if (matches) {
      return oem;
    }
  }
  return OEM.SAMSUNG;
};
export const addQueryParamToUrl = (
  url: string,
  querys: { key: string; value: string }[]
) => {
  try {
    const newUrl = new URL(url);
    querys.forEach((query) => {
      newUrl.searchParams.set(query.key, query.value);
    });
    return newUrl.toString();
  } catch (err) {
    return url;
  }
};

export const shouldGenerateNewSid = () => {
  const referrer = myWindow?.document.referrer;
  const url = myWindow?.location.hostname.split(".") || [];
  const domain = url?.slice(-2).join(".");
  let isReferredFromShopDomain = referrer?.includes(domain);
  return (
    Number(myWindow?.history.length) <= 2 ||
    (referrer !== "" && !isReferredFromShopDomain)
  );
};

export const handleCreateSessionId = () => {
  const newSid = uuidv4();
  const { nFeed, sId = newSid } = getQueryParamObject();
  const url = myWindow?.location.hostname.split(".") || [];
  const domain = url.slice(-2).join(".");
  const oldCookie = getCookie(null, COOKIES.sId, domain);
  if (nFeed) {
    // redirect from native shoptab to web
    setCookie(null, COOKIES.sId, sId, domain);
  } else if (!oldCookie) {
    setCookie(null, COOKIES.sId, newSid, domain);
  } else if (shouldGenerateNewSid()) {
    setCookie(null, COOKIES.sId, newSid, domain);
  }
};

export const getSessionId = () => {
  const url = myWindow?.location.hostname.split(".") || [];
  const domain = url.slice(-2).join(".");
  return getCookie(null, COOKIES.sId, domain);
};

export const getClickSessionInfo = () => {
  const clickSessionStr = sessionStorage.getItem("clickSession");
  let clickSession: any = {};
  try {
    clickSession = JSON.parse(clickSessionStr || "{}");
  } catch (err) {
    console.error(err);
  }

  return clickSession;
};

export const getPageSessionInfo = () => {
  const pageSessionStr = sessionStorage.getItem("pageSession");
  let pageSession: any = {};
  try {
    pageSession = JSON.parse(pageSessionStr || "{}");
  } catch (err) {
    console.error(err);
  }

  return pageSession;
};

export const getQueryParamObject = () => {
  let search = myWindow?.location?.search.substring(1) || "";
  try {
    if (!search || typeof search !== "string") {
      return {};
    }
    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  } catch (err) {
    console.error(err);
    const newSearch = search?.replaceAll("?", "&");
    return JSON.parse(
      '{"' +
        decodeURI(newSearch)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  }
};

export const convertObjectToKeyValuePair = (obj: any = {}) => {
  return (Object.entries(obj || {}) || []).map((entry: any[]) => {
    const [key, value] = entry;
    return { key, value };
  });
};

export const convertObjectToQueryString = (obj: any = {}) => {
  const queryString = new URLSearchParams(obj || {})?.toString();
  return queryString;
};

export const isShopTabDomain = () => {
  const url = myWindow?.location.hostname.split(".") || [];
  const domain = url.slice(-2).join(".");
  const domainKey = process.env.NEXT_PUBLIC_RETURNS_LINK_SHOP_DOMAIN_NAME;
  if (!domainKey) {
    return false;
  }
  // @ts-ignore
  return domain === Domain[domainKey || ""].toString();
};

export const isShipstreakDomain = () => {
  const url = myWindow?.location.hostname.split(".") || [];
  const domain = url.slice(-2).join(".");
  const domainKey = process.env.NEXT_PUBLIC_RETURNS_LINK_SHIPSTREAK_DOMAIN_NAME;
  if (!domainKey) {
    return false;
  }
  // @ts-ignore
  return domain === Domain[domainKey || ""].toString();
};

export const isEmpty = (value: any) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" &&
      value !== null &&
      Object.keys(value).length === 0)
  );
};
